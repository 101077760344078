(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ecs.factory.Auxiliary
   *
   * @description
   *
   */
  angular
    .module('ecs')
    .factory('Auxiliary', Auxiliary);

  function Auxiliary(wbResource) {
    var url = '';

    return wbResource('Auxiliary', url, {}, {
      getWorkingDays: {
        method: 'GET',
        url: 'workingDays',
        cache: true,
        isArray: true
      },
      getHeartbeat: {
        method: 'GET',
        url: 'heartbeat',
        cache: false
      }
    });
  }
}());
